import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMarkets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/market/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchActiveMarkets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/market/leerlistaactivos', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMarket(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/market/leermercado?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgrammingTasks(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/market/ProgramarTareas?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMarket(ctx, marketData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/market/recibemercado', { json: marketData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
