<template>
  <v-card
    v-if="data.marketData"
    class="app-invoice-editable"
  >
    <v-toolbar
      class="primary"
      short
    >
      <v-toolbar-title style="color:white;">
        Add/Edit Market
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        color="primary"
        small
        :disabled="!data.marketData.valid"
        @click="onSubmit"
      >
        Save Record
      </v-btn>
      <v-btn
        color="secondary"
        small
        outlined
        @click="regresarListado"
      >
        Return to List
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        small
        @click="onProgrammingTasks"
      >
        Programming Recurrent Tasks
      </v-btn>
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text>
      <v-form
        ref="form"
        v-model="data.marketData.valid"
        @submit.prevent="onSubmit"
      >
        <v-row dense>
          <v-col
            cols="12"
            md="3"
          >
            <label>Market's Short Name:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketShortName"
              v-uppercase
              outlined
              dense
              required
              :rules="validators.formatRegular"
              placeholder="Short Name of the Market"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Market's Description:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketDescription"
              v-uppercase
              outlined
              dense
              required
              :rules="validators.formatRegular"
              placeholder="Description of the Market"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Market Provider:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-select
              v-model="data.marketData.marketProvider"
              :items="providerList"
              item-text="data.marketData.marketProvider"
              item-value="data.marketData.marketProvider"
              placeholder="Select Provider"
              solo
              dense
              style="width:180px; height: 42px;"
              class="shrink"
              @change="onStatusChange"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Market's Zone:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketZone"
              v-uppercase
              outlined
              dense
              required
              placeholder="Market Zone (this field group Markets with the same zone)"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>OnSale Url:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketUrlDaily"
              outlined
              dense
              required
              :rules="validators.formatRegular"
              placeholder="On Sale URL"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Sold 1yr Url:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketUrlHistorical"
              outlined
              dense
              required
              :rules="validators.formatRegular"
              placeholder="Sold 1yr Url"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Sold 6mo Url:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketUrlParameters"
              outlined
              dense
              placeholder="Sold 6mo Url"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Sold 3mo Url:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketUrlParameters02"
              outlined
              dense
              placeholder="Sold 3mo Url"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Solds Daily Url:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketUrlSolds"
              outlined
              dense
              placeholder="Daily Update Solds Url"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Sales Daily Url:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketUrlSales"
              outlined
              dense
              placeholder="Daily Update Sales Url"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Market Latitude:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketLatitude"
              outlined
              dense
              required
              :rules="validators.formatFloat"
              placeholder="Market Latitude"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Market Longitude:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.marketData.marketLongitude"
              outlined
              dense
              required
              :rules="validators.formatFloat"
              placeholder="Market Longitude"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Is Active?</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-checkbox
              v-model="data.marketData.isActive"
              outlined
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import themeConfig from '@themeConfig'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mdiDotsVertical, mdiClose, mdiCogOutline } from '@mdi/js'
import ctMarketStore from './ctMarketStore'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const marketDataLocal = ref({})
    marketDataLocal.value = JSON.parse(JSON.stringify(props.data))

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ctMarketStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const grabarInformacion = () => {
      marketDataLocal.value = JSON.parse(JSON.stringify(props.data))
      const userData = JSON.parse(localStorage.getItem('userData'))
      console.log(marketDataLocal.value)
      console.log(userData)
    }

    const regresarListado = () => {
      router.go(-1)
    }

    const providerList = ref(['REDFIN', 'ZILLOW', 'REALTOR'])

    const onSubmit = () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      marketDataLocal.value = JSON.parse(JSON.stringify(props.data))

      marketDataLocal.value.marketData.usuarioRegistro = userData.email
      marketDataLocal.value.marketData.usuarioModifico = userData.email

      store.dispatch('app-invoice/addMarket', marketDataLocal.value.marketData).then(() => {
        Swal.fire({
          text: 'Market Added Successfully!',
          toast: true,
          position: 'top-right',
          icon: 'success',
          customClass: {
            popup: 'colored-toast',
          },
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.go(-1)
      })
    }

    const onProgrammingTasks = () => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This action will create the programming task to scraping web data for this Market.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create tasks!',
      }).then(result => {
        if (result.isConfirmed) {
          marketDataLocal.value = JSON.parse(JSON.stringify(props.data))

          store.dispatch('app-invoice/fetchProgrammingTasks', { id: marketDataLocal.value.marketData.id }).then(() => {
            Swal.fire({
              text: 'Tasks created successfully!',
              toast: true,
              position: 'top-right',
              iconColor: 'white',
              icon: 'success',
              customClass: {
                popup: 'colored-toast',
              },
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            })
          })

          router.go(-1)
        }
      })
    }

    return {
      grabarInformacion,
      onSubmit,
      regresarListado,
      onProgrammingTasks,
      marketDataLocal,
      providerList,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      validators: {
        // Condiciones
        formatRegular: [
          v => !!v || 'This field is mandatory',
        ],
        formatFloat: [
          v => !Number.isNaN(Number(v)) || 'The value of the field must be a floating number',
        ],
      },

      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
