<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="11"
    >
      <markets-editable :data="marketData">
      </markets-editable>
    </v-col>

    <!-- Right Column: Invoice Action -->
  </v-row>
</template>

<script>
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import MarketsEditable from './MarketsEditable.vue'
import ctMarketStore from './ctMarketStore'

export default {
  components: {
    MarketsEditable,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const isInvoiceSendSidebarActive = ref(false)
    const isAddPaymentSidebarActive = ref(false)

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ctMarketStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const marketData = ref({})

    store
      .dispatch('app-invoice/fetchMarket', { id: router.currentRoute.params.id })
      .then(response => {
        marketData.value = {
          marketData: response.data,
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          marketData.value = undefined
        }
      })

    return {
      marketData,

      // drawer
      isInvoiceSendSidebarActive,
      isAddPaymentSidebarActive,

      // Icons
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
</style>
